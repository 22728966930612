<template>
  <div>
    <!-- Filters -->
    <b-card>
      <b-row>
        <b-col cols="3">
          <b-form-group
            label="사이트"
            label-for="filter-site"
            label-align="left"
            class="font-small-3"
          >
            <b-form-select
              v-if="siteOptions"
              id="filter-site"
              ref="filter-site"
              v-model="siteFilter"
              :options="siteOptions"
              class="font-small-3"
              size="sm"
              style="color:rgb(229,166,48); "
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="권한"
            label-for="filter-Role"
            label-align="left"
            class="font-small-3"
          >
            <b-form-select
              v-if="roleOptions"
              id="filter-Role"
              v-model="roleFilter"
              :options="roleOptions"
              class="font-small-3"
              size="sm"
              style="color:rgb(229,166,48); "
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="랭크"
            label-for="filter-Rank"
            label-align="left"
            class="font-small-3"
          >
            <b-input-group class="input-group-merge">
              <b-form-select
                v-if="rankOptions"
                id="filter-Rank"
                v-model="rankFilter"
                :options="rankOptions"
                class="font-small-3"
                size="sm"
                style="color:rgb(229,166,48); "
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="상태"
            label-for="filter-Status"
            label-align="left"
            class="font-small-3"
          >
            <b-form-select
              id="filter-Status"
              v-model="statusFilter"
              :options="statusOptions"
              class="font-small-3"
              size="sm"
              style="color:rgb(229,166,48); "
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      no-body
      class="mb-0"
    >
      <div>
        <b-row class="m-1">
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <b-form-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              size="sm"
              class="per-page-selector d-inline-block mx-50"
              style="width: 100px;"
            />
          </b-col>
          <!-- Search -->
          <b-col>
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="searchFilter"
                type="search"
                placeholder="검색: 상위/추천, 닉네임/이름, 아이디, 전화번호 ..."
                style="color: #99dff1"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!searchFilter"
                  @click="searchFilter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-table
              ref="refUserListTable"
              primary-key="id"
              hover
              small
              responsive
              show-empty
              empty-text="No matching records found"
              :items="paginatedItems"
              :fields="tableColumns"
              class="position-relative small"
              style="text-align: center !important;"
              @row-clicked="rowClicked"
            >
              <!-- Column: No -->
              <template #cell(no)="data">
                {{ (currentPage - 1) * perPage + data.index + 1 }}
              </template>
              <!-- Column: Role -->
              <template #cell(role)="data">
                <b-media class="align-content-center">
                  <template>
                    <b-avatar
                      size="32"
                      :src="data.item.role"
                      :text="avatarText(data.item.role)"
                      :variant="`${resolveUserRoleVariant(data.item.role)}`"
                    />
                  </template>
                </b-media>
              </template>
              <!-- Column: Userid / nickname -->
              <template #cell(userid)="data">
                <div
                  class="text-success font-weight-bold d-block text-nowrap"
                  style="text-align: left !important; min-width: 7rem; text-decoration: underline; text-underline-offset: 5px;"
                >
                  {{ data.item.userid }}
                </div>
                <div
                  class=""
                  style="text-align: left !important; min-width: 7rem; text-underline-offset: 5px;"
                >
                  {{ data.item.nickname }}
                </div>
              </template>
              <!-- Column: recommandNickname -->
              <template #cell(recommendNickname)="data">
                <div
                  class="text-left"
                  style="color:rgb(229,166,48); min-width: 5rem"
                >
                  {{ data.item.recommendNickname }}
                </div>
              </template>
              <!-- Column: mobile -->
              <template #cell(mobile)="data">
                <div
                  class="text-center"
                  style="min-width: 8rem"
                >
                  {{ data.item.mobile }}
                </div>
              </template>
              <!-- Column: rank -->
              <template #cell(rank)="data">
                <b-avatar
                  size="28"
                  style="text-align: center;"
                  :src="`https://agress-assets.s3.ap-northeast-2.amazonaws.com/img_rank/` + siteFilter + `_` + data.item.rank + `.png`"
                />
              </template>
              <!-- Column: cash -->
              <template #cell(cash)="data">
                <div
                  class="text-right"
                  style="color:rgb(196,186,138); min-width: 5rem"
                >
                  {{ Number(data.item.cash).toLocaleString() }}
                </div>
              </template>
              <template #cell(point)="data">
                <div
                  class="text-right"
                  style="color:rgb(196,186,138); min-width: 5rem"
                >
                  {{ Number(data.item.point).toLocaleString() }}
                </div>
              </template>
              <!-- Column: betTotal -->
              <template #cell(betTotal)="data">
                <div
                  class="text-right"
                  style="color:rgb(196,186,138); min-width: 6rem"
                >
                  {{ Number(data.item.sportBettingAmount + data.item.casinoBettingAmount + data.item.slotBettingAmount).toLocaleString() }}
                </div>
              </template>
              <template #cell(sportBettingAmount)="data">
                <div
                  class="text-right"
                  style="color:rgb(196,186,138); min-width: 6rem"
                >
                  {{ Number(data.item.sportBettingAmount).toLocaleString() }}
                </div>
              </template>
              <template #cell(casinoBettingAmount)="data">
                <div
                  class="text-right"
                  style="color:rgb(196,186,138); min-width: 6rem"
                >
                  {{ Number(data.item.casinoBettingAmount).toLocaleString() }}
                </div>
              </template>
              <template #cell(slotBettingAmount)="data">
                <div
                  class="text-right"
                  style="color:rgb(196,186,138); min-width: 6rem"
                >
                  {{ Number(data.item.slotBettingAmount).toLocaleString() }}
                </div>
              </template>
              <!-- Column: depositTotal -->
              <template #cell(depositTotal)="data">
                <div
                  class="text-right"
                  style="color:rgb(196,186,138); min-width: 5rem"
                >
                  {{ Number(data.item.depositTotal).toLocaleString() }}
                </div>
              </template>
              <!-- Column: exchangeTotal -->
              <template #cell(exchangeTotal)="data">
                <div
                  class="text-right"
                  style="color:rgb(196,186,138); min-width: 5rem"
                >
                  {{ Number(data.item.exchangeTotal).toLocaleString() }}
                </div>
              </template>
              <template #cell(depositExchangeTotal)="data">
                <div
                  class="text-right"
                  style="color:rgb(196,186,138); min-width: 5rem"
                >
                  {{ Number(data.item.depositTotal - data.item.exchangeTotal).toLocaleString() }}
                </div>
              </template>
              <!-- Column: enrollDate -->
              <template #cell(enrollDate)="data">
                <div
                  class="font-weight-bold d-block text-nowrap"
                  style="text-align: center !important"
                >
                  {{ data.item.enrollDate | formatDate }}
                </div>
                <div
                  class="text-info"
                  style="text-align: center !important"
                >
                  {{ data.item.signinLastDate | formatDate }}
                </div>
              </template>
              <!-- Column: enrollIp -->
              <template #cell(enrollIp)="data">
                <div
                  v-b-tooltip.hover.bottom.v-danger="data.item.enrollIp"
                  class="font-weight-bold"
                  style="text-align: center; min-width: 6rem"
                >
                  {{ data.item.enrollIp ? data.item.enrollIp.substring(0, 14) : '' }}
                </div>
                <div
                  v-b-tooltip.hover.bottom.v-danger="data.item.signinLastIp"
                  class="text-info"
                  style="text-align: center; min-width: 6rem"
                >
                  {{ data.item.signinLastIp ? data.item.signinLastIp.substring(0, 14) : '' }}
                </div>
              </template>
              <template #cell(signinTotal)="data">
                <div
                  class="text-info"
                  style="text-align: center !important"
                >
                  {{ data.item.signinTotal }}
                </div>
              </template>
              <!-- Column: Status -->
              <template #cell(status)="data">
                <b-badge
                  pill
                  :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                  class="text-capitalize"
                >
                  <!-- {{ data.item.status }} -->
                  {{ convertUserStatus(data.item.status) }}
                </b-badge>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="mx-2 mb-2">
              <b-row>
                <!-- Table Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-left justify-content-left justify-content-sm-start"
                  style="margin-bottom: 0.5rem;"
                >
                  <span class="font-small-2">{{ pageFrom }}~{{ pageTo }} / 총 {{ pageOf }} </span>
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                  class="d-flex align-items-center justify-content-center justify-content-sm-center"
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol,
  BFormGroup,
  BFormInput, BInputGroup, BInputGroupAppend,
  BFormSelect,
  BButton,
  BTable, BPagination,
  BBadge, BMedia, BAvatar,
  VBTooltip,
} from 'bootstrap-vue'
import { avatarText, formatDate } from '@core/utils/filter'

import useUsersList from './useUsersList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BButton,
    BTable,
    BPagination,
    BBadge,
    BMedia,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    // Table Handlers
    const tableColumns = [
      { key: 'no', label: 'No.' },
      { key: 'id', sortable: false, label: '번호', thClass: 'd-none', tdClass: 'd-none' },
      { key: 'role', sortable: false, label: '권한' },
      { key: 'userid', sortable: false, label: '아이디/닉네임' },
      { key: 'recommendNickname', sortable: false, label: '상위' },
      { key: 'mobile', sortable: false, label: '전화번호' },
      { key: 'rank', sortable: false, label: '등급' },
      { key: 'cash', sortable: false, label: '통합캐쉬' },
      { key: 'point', sortable: false, label: '포인트' },
      { key: 'betTotal', sortable: false, label: '총배팅금' },
      { key: 'sportBettingAmount', sortable: false, label: '스포츠배팅금' },
      { key: 'casinoBettingAmount', sortable: false, label: '카지노배팅금' },
      { key: 'slotBettingAmount', sortable: false, label: '슬롯배팅금' },
      { key: 'depositTotal', sortable: false, label: '총충전금' },
      { key: 'exchangeTotal', sortable: false, label: '총환전금' },
      { key: 'depositExchangeTotal', sortable: false, label: '총충환금'},
      { key: 'enrollDate', sortable: false, label: '가입일/최근' },
      { key: 'enrollIp', sortable: false, label: '가입IP/최근' },
      { key: 'signinTotal', sortable: false, label: '접속' },
      { key: 'status', sortable: false, label: '상태' },
    ]

    const {

      /* Table Set */
      refUserListTable,
      rowClicked,

      /* TableOption */
      perPageOptions,
      currentPage,
      perPage,
      pageFrom,
      pageTo,
      pageOf,
      totalRows,
      searchFilter,

      /* Search Filter */
      siteFilter,
      roleFilter,
      statusFilter,
      rankFilter,

      /* Select Options */
      siteOptions,
      roleOptions,
      statusOptions,
      rankOptions,

      /* fetchData */
      filteredItems,
      paginatedItems,

      // UI
      resolveUserRoleVariant,
      resolveUserStatusVariant,
      convertUserStatus,

    } = useUsersList()

    return {
      /* Table Set */
      refUserListTable,
      rowClicked,

      /* TableOption */
      perPageOptions,
      currentPage,
      perPage,
      pageFrom,
      pageTo,
      pageOf,
      totalRows,
      searchFilter,

      /* Search Filter */
      siteFilter,
      roleFilter,
      statusFilter,
      rankFilter,

      /* Select Options */
      siteOptions,
      roleOptions,
      statusOptions,
      rankOptions,

      /* fetchData */
      filteredItems,
      paginatedItems,
      tableColumns,

      // UI
      resolveUserRoleVariant,
      resolveUserStatusVariant,
      convertUserStatus,

      avatarText,
      formatDate,

    }
  },
}
</script>
